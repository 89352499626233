import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";

import { Form, Field } from "react-final-form";

import { useAuth0 } from "@auth0/auth0-react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import LPEntityService from "../service/LPEntityService";
import UserService from "../service/UserService";
import LPEntityUserService from "../service/LPEntityUserService";
import FormStyles from "./FormStyles";
import BaseService from "../service/BaseService";
import DataTable from "react-data-table-component";
import {
  AddCircle,
  ArrowDown,
  Menu,
  More,
  MoreSquare,
  Pause,
  Refresh,
  TextalignJustifycenter,
} from "iconsax-react";
import { ButtonGroup, ButtonToolbar } from "react-bootstrap";

export default function LPTable(props) {
  const { getAccessTokenSilently } = useAuth0();

  const { editEnabled = false } = props;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [users, setUsers] = useState([]);
  const [connections, setConnections] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(undefined);
  const [modal, setModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [filter, setFilter] = useState({ usePagination: false, query: "" });

  const close = () => {
    setFormData(undefined);
    setModal(false);
  };

  const closeUser = () => {
    setUserModal(false);
  };

  const showFormData = (data) => {
    setFormData(data);
    setModal(true);
  };

  const showUserForm = async (data) => {
    const token = await getAccessTokenSilently();
    setFormData(undefined);
    setConnections([]);
    let r = await LPEntityUserService.getLPEntityUserByCode(token, data.code);
    setConnections(r);
    setFormData(data);
    setUserModal(true);
  };

  const editFormData = async (data) => {
    try {
      const token = await getAccessTokenSilently();
      if (formData.code) {
        await LPEntityService.editLPEntity(token, formData.code, data);
      } else {
        await LPEntityService.addLPEntity(token, data);
      }
      loadData();
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const deleteRow = async (code) => {
    try {
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const token = await getAccessTokenSilently();
              await LPEntityService.deleteLPEntity(token, code);
              loadData();
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const loadData = async () => {
    try {
      const token = await getAccessTokenSilently();
      let resp = await LPEntityService.getAll(token);
      setData(resp);

      let resp2 = await UserService.getAll(token);
      resp2 = resp2.filter((u) => u.status !== "BLOCKED");
      resp2 = resp2.sort((a, b) => a.firstName.localeCompare(b.firstName)); // Sort by firstName

      setUsers(resp2);
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup
    };
  }, []);

  useEffect(() => {
    let filteredData = data;
    if (filter.query) {
      filteredData = data.filter((d) => {
        return (
          d.companyName.toLowerCase().includes(filter.query.toLowerCase()) ||
          d.meta?.legalName
            ?.toLowerCase()
            .includes(filter.query.toLowerCase()) ||
          d.code.toLowerCase().includes(filter.query.toLowerCase())
        );
      });
    }
    if (filter.type) {
      filteredData = data.filter((d) => {
        return d.meta?.type === filter.type;
      });
    }
    setFilteredData(filteredData);
  }, [data, filter]);

  const options = [
    { value: "Owner", label: "Owner" },
    { value: "Admin", label: "Admin" },
    { value: "Viewer", label: "Viewer" },
  ];

  const getUserEmail = (userId) => {
    return users.find((u) => u.id.toString() === userId.toString());
  };

  const getLPUser = () => {
    let connectionsId = connections.map((c) => parseInt(c.userId));

    let lps = users
      .filter((u) => u.type === "LP")
      .filter((u) => connectionsId.indexOf(u.id) === -1);

    lps = lps.map((opt) => {
      return (
        <option key={opt.email} value={opt.id}>
          {opt.firstName} {opt.lastName} - {opt.email}
        </option>
      );
    });

    lps = <optgroup label="LP">{lps}</optgroup>;

    let vts = users
      .filter((u) => u.type === "VT")
      .filter((u) => connectionsId.indexOf(u.id) === -1);

    vts = vts.map((opt) => {
      return (
        <option key={opt.email} value={opt.id}>
          {opt.firstName} {opt.lastName} - {opt.email}
        </option>
      );
    });
    vts = <optgroup label="VT">{vts}</optgroup>;

    return (
      <>
        {lps}
        {vts}
      </>
    );
  };

  const columns = [
    {
      compact: true,
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
      allowOverflow: false,
    },
    {
      compact: true,
      name: "Type",
      selector: (row) => row.meta?.type || "",
      sortable: true,
    },

    {
      grow: 2,
      name: "Entity Name",
      selector: (row) => row.companyName || "",
      sortable: true,
    },
    {
      grow: 2,
      name: "Legal Name",
      selector: (row) => row.meta?.legalName || "",
      sortable: true,
    },

    {
      name: "Joined",
      format: (row) => BaseService.getDateString(row.meta.subscription?.joined),
      selector: (row) => row.meta.subscription?.joined,
      sortable: true,
    },
    {
      compact: true,
      name: "Credits",
      selector: (row) => row.meta?.credits,
      sortable: true,
    },
    {
      compact: true,
      name: "# Users",
      selector: (row) => row.users?.length,
      sortable: true,
    },
    {
      name: "Expired",
      sortable: true,
      format: (row) =>
        BaseService.getDateString(row.meta.subscription?.expiredAt),
      selector: (row) => row.meta.subscription?.expiredAt,
    },
  ];

  if (editEnabled) {
    columns.push({
      name: "",
      ignoreRowClick: true,
      selector: (d) => {
        return (
          <UncontrolledDropdown className="rounded z-3">
            <DropdownToggle color={""}>
              <TextalignJustifycenter />
            </DropdownToggle>
            <DropdownMenu className="btn-light">
              <DropdownItem
                onClick={() => {
                  setIsEditing(true);
                  showFormData(d);
                }}
              >
                Edit
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  showUserForm(d);
                }}
              >
                Assign
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  deleteRow(d.code);
                }}
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    });
  }

  return (
    <Fragment>
      <Modal
        isOpen={modal}
        toggle={close}
        fullscreen={false}
        backdrop={true}
        fade={true}
        size={"xl"}
        scrollable={true}
      >
        <ModalHeader toggle={close}>
          {formData?.code ? "Edit LP Entity" : "Add LP Entity"}
        </ModalHeader>
        <ModalBody>
          {formData && (
            <FormStyles>
              <Form
                initialValues={{
                  companyName: formData.companyName,
                  code: formData.code,
                  phone: formData.phone,
                  description: formData.description,
                  countryCode: formData.countryCode,
                  meta: formData.meta || {},
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.code) {
                    errors.code = "Required";
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  await editFormData(values);
                  setModal(false);
                }}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                  valid,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={"form-fields"}>
                      <label>Company Name (Display)</label>
                      <Field name="companyName" component="input" type="text" />
                    </div>

                    <div className={"form-fields"}>
                      <label>Legal Name</label>
                      <Field
                        name="meta.legalName"
                        component="input"
                        type="text"
                      />
                    </div>

                    <div className={"form-fields"}>
                      <label>Code</label>
                      <Field
                        name="code"
                        component="input"
                        type="text"
                        readOnly={isEditing}
                      />
                    </div>

                    <div className={"form-fields"}>
                      <label>Country Code</label>
                      <Field name="countryCode" component="select">
                        <option value="" />
                        <option value="US">US</option>
                        <option value="CA">CA</option>
                        <option value="SG">SG</option>
                        <option value="">Other</option>
                      </Field>
                    </div>

                    <div className={"form-fields"}>
                      <label>Phone</label>
                      <Field name="phone" component="input" type="tel" />
                    </div>

                    <h3>Membership</h3>

                    <div className={"form-fields"}>
                      <label>Type</label>
                      <Field
                        name="meta.type"
                        component="select"
                        required={true}
                      >
                        <option value="" />
                        <option value="FO">FO</option>
                        <option value="ALP">ALP</option>
                      </Field>
                    </div>

                    <div className={"form-fields"}>
                      <label>Credits</label>
                      <Field
                        name="meta.credits"
                        component="input"
                        type="number"
                      />
                    </div>

                    <div className={"form-fields"}>
                      <label>Joined</label>
                      <Field name="meta.joined" component="input" type="date" />
                    </div>

                    <div className={"form-fields"}>
                      <label>Expired</label>
                      <Field
                        name="meta.subscription.expiredAt"
                        component="input"
                        type="date"
                      />
                    </div>

                    <Field
                      name="description"
                      render={({ input, meta }) => (
                        <div className={"form-fields"}>
                          <label>Description</label>
                          <textarea {...input} rows="4" />
                        </div>
                      )}
                    />

                    <div className="buttons">
                      <Button
                        type="submit"
                        disabled={submitting || !valid}
                        className={"btn"}
                      >
                        {formData?.code ? "Save" : "Add"}
                      </Button>
                      <button
                        type="button"
                        onClick={form.reset}
                        disabled={submitting}
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                )}
              />
            </FormStyles>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={close}>
            close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={userModal}
        toggle={closeUser}
        fullscreen={false}
        backdrop={true}
        fade={true}
        size={"xl"}
        scrollable={true}
      >
        <ModalHeader toggle={closeUser}>
          Assign User to LP Entity [{formData?.companyName} - {formData?.code}]
        </ModalHeader>
        <ModalBody>
          <>
            {formData && (
              <FormStyles>
                <Form
                  onSubmit={async (values, { setSubmitting }) => {
                    let data = {
                      userId: values.user,
                      role: values.role,
                      lpEntityCode: formData.code,
                    };

                    if (!data.userId || !data.role) {
                      return;
                    }

                    const token = await getAccessTokenSilently();

                    await LPEntityUserService.addLPEntityUser(token, data);
                    showUserForm(formData);
                  }}
                  render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className={"form-fields"}>
                        {" "}
                        <label>User</label>
                        <Field name="user" component="select">
                          <option value="" />
                          {getLPUser()}
                        </Field>
                      </div>

                      <div className={"form-fields"}>
                        <label>Role</label>
                        <Field name="role" component="select">
                          <option value="" />
                          {options.map((opt) => {
                            return <option key={opt.label}>{opt.label}</option>;
                          })}
                        </Field>
                      </div>

                      <Button
                        type={"submit"}
                        className={"m-2"}
                        color="info"
                        block={true}
                      >
                        Submit
                      </Button>
                    </form>
                  )}
                />
              </FormStyles>
            )}

            <Card body className="mt-5 shadow rounded-0">
              {connections.length > 0 && (
                <Label>
                  Existing LP Entity Users for [{formData?.companyName} -{" "}
                  {formData?.code}]
                </Label>
              )}

              <ListGroup className={"m-2"}>
                {connections.map((connection, index) => {
                  return (
                    <ListGroupItem key={index} className={"m-1"}>
                      [{connection.role}]{" "}
                      {getUserEmail(connection.userId)?.email}
                      <Button
                        className={"float-end"}
                        color="danger"
                        onClick={async () => {
                          setUserModal(false);
                          confirmAlert({
                            title: "Confirm to submit",
                            message: "Are you sure to do this.",
                            buttons: [
                              {
                                label: "Yes",
                                onClick: async () => {
                                  const token = await getAccessTokenSilently();
                                  await LPEntityUserService.deleteLPUserEntity(
                                    token,
                                    connection.id
                                  );
                                  showUserForm(formData);
                                },
                              },
                              {
                                label: "No",
                                onClick: () => {},
                              },
                            ],
                          });
                        }}
                      >
                        Remove
                      </Button>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </Card>
          </>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeUser}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {editEnabled && (
        <Container className="pt-2" fluid>
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              <Button
                color="primary"
                onClick={() => {
                  loadData();
                }}
              >
                <Refresh color="#FFF" />
              </Button>
            </ButtonGroup>
            <ButtonGroup className="me-2">
              <Button
                color="primary"
                onClick={() => {
                  setIsEditing(false);
                  showFormData({});
                }}
              >
                <AddCircle />
              </Button>
            </ButtonGroup>
            <ButtonGroup className="me-2">
              <Button
                color="link"
                onClick={() => {
                  let currentFilter = { ...filter };
                  currentFilter.usePagination = !currentFilter.usePagination;
                  setFilter(currentFilter);
                }}
              >
                <Pause color={filter.usePagination ? "#000000" : "#cccccc"} />
              </Button>
            </ButtonGroup>

            <ButtonGroup className="me-2">
              <UncontrolledDropdown>
                <DropdownToggle caret color={"primary"}>
                  Type {filter?.type ? `- ${filter.type}` : " - Show All"}
                </DropdownToggle>
                <DropdownMenu className="btn-light">
                  <DropdownItem
                    onClick={() => {
                      let currentFilter = { ...filter };
                      currentFilter.type = undefined;
                      setFilter(currentFilter);
                    }}
                  >
                    Show All
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      let currentFilter = { ...filter };
                      currentFilter.type = "FO";
                      setFilter(currentFilter);
                    }}
                  >
                    FO Only
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      let currentFilter = { ...filter };
                      currentFilter.type = "ALP";
                      setFilter(currentFilter);
                    }}
                  >
                    ALP Only
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>

            <InputGroup className="me-2">
              <Input
                placeholder="Search by text"
                inputMode={"text"}
                value={filter.query}
                onChange={async (e) => {
                  let currentFilter = { ...filter };
                  currentFilter.query = e.target.value;
                  setFilter(currentFilter);
                }}
              />
            </InputGroup>
          </ButtonToolbar>
        </Container>
      )}

      <DataTable
        data={filteredData}
        columns={columns}
        dense={false}
        pagination={filter.usePagination}
        responsive={true}
        sortIcon={<ArrowDown className="mx-3" />}
      />
    </Fragment>
  );
}
