import React, { useEffect, useState } from "react";
import { HashRouter, BrowserRouter, Route, Routes } from "react-router-dom";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Login from "./views/Login";
import Welcome from "./views/Welcome";
import Admin from "./views/Admin";
import Profile from "./views/LPDashboard";
import VCFunds from "./views/VCFunds";
import Privacy from "./views/Privacy";
import FundReport from "./views/FundReport";
import About from "./views/About";
import FAQ from "./views/FAQ";
import Signup from "./views/Signup";
import { useAuth0 } from "@auth0/auth0-react";

// styles
import "./App.scss";

// theme
import { ThemeProvider } from "styled-components";
import { useDarkMode } from "./useDarkMode";
import { lightTheme, darkTheme } from "./theme";
import { GlobalStyles } from "./global";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import BaseService from "./service/BaseService";
import Terms from "./views/Terms";

import ScrollToTop from "./ScrollToTop";
import { UserContext } from "./UserContext";
import EditFund from "./views/EditFund";
import FundStats from "./views/FundStats";
import EnterInvitation from "./components/EnterInvitation";
import UserProfile from "./components/UserProfile";
import AppCookieBanner from "./components/AppCookieBanner";
import ManageTeam from "./views/ManageTeam";
import PostInvestment from "./views/PostInvestment";
import EditPostInvestment from "./views/EditPostInvestment";
import LPDashboard from "./views/LPDashboard";
import News from "./views/News";
import VCFundLibrary from "./components/VCFundLibrary";

const signUpRoute = "signup";
const invitationRoute = "/#/invitation";
initFontAwesome();

const App = (props) => {
  const { user, isLoading, error, logout, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [context, setContext] = useState(undefined);

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin + "/#/" + signUpRoute + "?failed=1",
      },
    });

  const loadData = async () => {
    try {
      setLoading(true);
      const searchParams = new URLSearchParams(document.location.search);

      let err = searchParams.get("error");
      let goSignUp = false;
      if (err === "unauthorized") {
        goSignUp = true;
      } else {
        const token = await getAccessTokenSilently();
        let c = await BaseService.login(token);
        setContext(c.data);
        if (c.data && c.data.status === "INVITED") {
          window.location = invitationRoute;
          return;
        } else if (c.data && c.data.status !== "ACTIVE") {
          goSignUp = true;
        }
      }

      if (goSignUp) {
        logoutWithRedirect();
        return;
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    return () => {
      // Side-effect cleanup...
    };
  }, []);

  const [theme, componentMounted] = useDarkMode();

  const themeMode = theme === "light" ? lightTheme : darkTheme;

  if (!componentMounted) {
    return <div />;
  }

  return (
    <HashRouter>
      <ThemeProvider theme={themeMode}>
        <GlobalStyles />

        {/* <Toggle
          theme={theme}
          toggleTheme={() => {
            setContext({
              ...context,
              theme: theme === "dark" ? "light" : "dark",
            });
            toggleTheme(theme);
          }}
        /> */}

        <UserContext.Provider value={[context, setContext]}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <div id="app">
                <ScrollToTop />

                <NavBar />

                <Routes>
                  <Route
                    path="/"
                    exact="true"
                    element={
                      loading ? (
                        <Loading />
                      ) : user ? (
                        <Welcome stay={false} />
                      ) : (
                        <Home />
                      )
                    }
                  />
                  <Route
                    path="/login"
                    exact="true"
                    element={<Login stay={true} />}
                  />
                  <Route
                    path="/how-to-invest"
                    exact="true"
                    element={<Welcome stay={true} />}
                  />
                  <Route
                    path="/dashboard"
                    exact="true"
                    element={<LPDashboard />}
                  />
                  <Route
                    path="/profile"
                    exact="true"
                    element={<UserProfile />}
                  />
                  <Route
                    path="/manage-team"
                    exact="true"
                    element={<ManageTeam />}
                  />
                  <Route
                    path="/dashboard/post-investment/:fundName"
                    exact="true"
                    element={<PostInvestment />}
                  />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/invitation" element={<EnterInvitation />} />
                  <Route
                    path="/admin/editFundDetail/:fundName"
                    element={<EditFund />}
                  />
                  <Route
                    path="/admin/fundStats/:fundName"
                    element={<FundStats />}
                  />
                  <Route
                    path="/admin/fundStats/:fundName/postInvestment"
                    element={<FundStats />}
                  />
                  <Route
                    path="/admin/fundStats/:fundName/editPostInvestment/:lpEntityCode"
                    element={<EditPostInvestment />}
                  />
                  <Route path="/terms-of-service" element={<Terms />} />
                  <Route
                    path="/privacy-policy"
                    exact="true"
                    element={<Privacy />}
                  />
                  <Route
                    path="/fund-gallery"
                    element={
                      <VCFunds
                        tiers={[1, 2]}
                        additionalFundTiers={[3, 4, 5, 6]}
                        showOtherFund={false}
                        alpMode={
                          context?.meta?.defaultLPType === "ALP" ? true : false
                        }
                        showAdditionalFund={false}
                      />
                    }
                  />
                  <Route
                    path="/fund-gallery/V0-selected"
                    element={
                      <VCFunds
                        tiers={[1]}
                        showOtherFund={false}
                        showAdditionalFund={false}
                      />
                    }
                  />
                  <Route path="/portfolio-updates" element={<News />} />
                  <Route
                    path="/fund-gallery/fund-library"
                    element={<VCFundLibrary />}
                  />
                  <Route
                    path="/fund-gallery/open-for-waitlist"
                    element={
                      <VCFunds
                        tiers={[6]}
                        title={"Funds Open for Waitlist"}
                        hideCategoryTitle={true}
                      />
                    }
                  />
                  {/* <Route path="/about" element={<About />} /> */}
                  <Route path={"/" + signUpRoute} element={<Signup />} />
                  <Route
                    path="/fund-detail/:fundName"
                    element={<FundReport />}
                  />
                </Routes>
              </div>
              <Footer />
            </>
          )}
        </UserContext.Provider>
      </ThemeProvider>

      <AppCookieBanner />
    </HashRouter>
  );
};

export default App;
